import {h} from 'preact';
import {Link} from 'preact-router/match';
import style from './style.css';

import registerZH from './img/注册会员.svg'
import registerEN from './img/registerEN.svg'

import weibo from './img/微博-01.svg'
import weixin from './img/微信-01.svg'
import ins from './img/ins-01.svg'
import fb from './img/facebook-01.svg'
import xh from './img/小红书.svg'
import lz from './img/绿洲.svg'

import copyright from './img/copyright.svg'
import {route} from "preact-router";
import {useContext} from "preact/hooks";
import Lang from "../../lang";

const Footer = () => {
    const lang = useContext(Lang);
    const register = lang === 'en' ? registerEN : registerZH;
    return (
        <div class={style.footer}>
            <div>
                <img onClick={() => route("/member", true)} src={register}/>
                <div class={style.icon}>
                    <img src={weibo} onClick={() => iconClick('weibo')}/>
                    <img src={weixin} onClick={() => iconClick('weixin')}/>
                    <img src={ins} onClick={() => iconClick('ins')}/>
                    <img src={fb} onClick={() => iconClick('fb')}/>
                    <img src={xh} onClick={() => iconClick('xh')}/>
                    <img src={lz} onClick={() => iconClick('lz')}/>
                </div>
            </div>
            <img src={copyright}/>
        </div>
    )
};

function iconClick(from) {
    if (from === 'weibo') {
        window.location.href = "https://weibo.com/u/6060347110?nick=Larutadevia&is_hot=1";
    } else if (from === 'ins') {
        window.location.href = "https://www.instagram.com/larutadevia/";
    } else if (from === 'fb') {
        window.location.href = "https://business.facebook.com/pg/larutadevia/posts/?business_id=384747595366534";
    } else if (from === 'weixin') {
        route("/qrcode/0");
    } else if (from === 'xh') {
        route("/qrcode/1");
    } else if (from === 'lz') {
        route("/qrcode/2");
    }
}

export default Footer;
