import style from './style.css';

import logo from './img/logo.svg'
import close from './img/close.svg'
import searchBTN from './img/search.svg'

import menu from './img/menu.svg'
import menu_arrow from './img/箭头.png'

import en from './img/EN.svg';
import cn from './img/CN.svg';

import {Component} from "preact";
import {route} from "preact-router";
import {useContext, useRef} from "preact/hooks";
import Lang from "../../lang";

class Header extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            search: false
        };
    }

    input = useRef(null);
    searchDiv = useRef(null);

    menu = [
        {
            "zh": "品牌介绍",
            "en": "BRAND",
            "url": "/brand"
        },
        {
            "zh": "本季潮流",
            "en": "SEASON'S NEW",
            "url": "/fashion"
        },
        {
            "zh": "拉如精选",
            "en": "SELECTION",
            "url": "/category"
        },
        {
            "zh": "品牌店铺",
            "en": "STORE",
            "url": "/stores"
        },
        {
            "zh": "品牌新闻",
            "en": "NEWS",
            "url": "/news"
        }
    ]

    render(_, {value}) {
        const {open, search} = this.state;
        const lang = useContext(Lang);

        return (
            <Fragment>
                <div className={style.header}>
                    <img onClick={() => {
                        this.setState({
                            open: false,
                            search: false
                        })
                        this.menuClick("/");
                    }} className="logo" src={logo}/>
                    <div className={style.ops}>
                        <img onClick={this.props.toggleLang} src={lang === 'zh' ? en : cn}/>
                        <img onClick={this.toggleSearch} src={search ? close : searchBTN}/>
                        <img onClick={this.toggleMenu} style={{display: open ? "none" : "block"}} src={menu}/>
                        <img onClick={this.toggleMenu} style={{display: open ? "block" : "none", height: "14px"}} src={close}/>
                    </div>
                </div>

                {/*菜单*/}
                <div style={{display: open ? "block" : "none"}}
                     className={[style.menu, open ? style.menu_open : ""].join(" ")}>
                    <div>
                        {
                            this.menu.map(m => (
                                <div onClick={() => this.menuClick(m.url)} className={style.item}>
                                    <span>{m[lang]}</span>
                                    <img src={menu_arrow}/>
                                </div>
                            ))
                        }
                    </div>
                </div>

                {/*搜索*/}
                <div ref={this.searchDiv} style={{zIndex: search ? 1000 : -1000, visibility: search ? "visible" : "hidden"}} class={style.search}>
                    <div>
                        <form onSubmit={this.search}>
                            <input ref={this.input} type="search" value={value} onInput={this.onInput} />
                            <span onClick={() => {
                                this.setState({value: ""});
                                this.input.current.focus();
                            }}>{lang === 'en' ? 'DELETE' : '清除'}</span>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }

    componentWillUnmount() {
        document.body.style.overflow = "visible";
    }

    search = e => {
        e.preventDefault();
        this.setState({
            open: false,
            search: false
        })
        document.body.style.overflow = "visible";
        document.activeElement.blur();

        window.location.href = `/search/${this.state.value}`;
    }

    onInput = e => {
        const {value} = e.target;
        this.setState({value})
    }

    toggleMenu = () => {
        this.setState(prev => {
            const open = !prev.open;
            const search = prev.search;
            document.body.style.overflow = (open || search) ? "hidden" : "visible";
            return {open}
        });
    }

    closeSearch = () => {
        this.setState({
            open: false,
            search: false
        })

        this.searchDiv.current.style.visibility = "hidden";
        document.body.style.overflow = "visible";
    }

    toggleSearch = () => {
        this.setState(prev => {
            const search = !prev.search;
            const open = prev.open;
            document.body.style.overflow = (open || search) ? "hidden" : "visible";
            if (search) {
                this.searchDiv.current.style.visibility = "visible";
                this.input.current.focus();
            } else {
                document.activeElement.blur();
                this.searchDiv.current.style.visibility = "hidden";
            }
            return {search}
        });
    }

    menuClick = url => {
        document.body.style.overflow = "visible";
        this.setState({open: false})
        route(url);
    }
}

export default Header;
