import {Component} from 'preact';
import {Router} from 'preact-router';

import Header from './header';
import Footer from "./footer";
// Code-splitting is automated for routes
import Home from '../routes/home';
import Brand from "../routes/brand";
import Member from "../routes/member";
import Fashion from "../routes/fashion";
import Category from "../routes/category";
import Stores from "../routes/stores";
import News from "../routes/news";
import NewsContent from "../routes/news/newsContent";
import List from "../routes/category/List";
import Item from "../routes/category/item";

import Lang from '../lang';
import QRCode from "../routes/qrcode";
import Search from "../routes/category/List/search";

export default class App extends Component {

    constructor() {
        super();

        const lang = "en" === localStorage.lang ? "en" : "zh";
        this.state = {
            lang
        }
    }

    /** Gets fired when the route changes.
     *    @param {Object} event        "change" event from [preact-router](http://git.io/preact-router)
     *    @param {string} event.url    The newly routed URL
     */
    handleRoute = e => {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }
    };

    toggleLang = () => {
        this.setState(prev => {
            const lang = prev.lang === 'zh' ? 'en' : 'zh';
            localStorage.lang = lang;
            return {
                lang
            }
        })
    }


    render() {
        return (
            <div id="app">
                <Lang.Provider value={this.state.lang}>
                    <Header toggleLang={this.toggleLang}/>
                    <Router onChange={this.handleRoute}>
                        <Home path="/"/>
                        <Brand path="/brand"/>
                        <Member path="/member"/>
                        <Fashion path="/fashion"/>
                        <Category path="/category"/>
                        <Stores path="/stores"/>
                        <News path="/news"/>
                        <NewsContent path="/news/:mark"/>
                        <List path="/list/:type"/>
                        <Item path="/item/:code"/>
                        <QRCode path="/qrcode/:id"/>
                        <Search path="/search/:keywords" />
                    </Router>
                    <Footer/>
                </Lang.Provider>
            </div>
        );
    }
}
