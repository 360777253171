import {Component} from "preact";
import ReactHtmlParser from "react-html-parser";

import style from './style.css'
import {useContext} from "preact/hooks";
import Lang from "../../lang";

export default class NewsContent extends Component {
    constructor() {
        super();
        this.state = {
            content: ""
        }
    }
    lang = useContext(Lang);

    componentDidMount() {
        this.loadData(this.lang);
    }

    loadData = lang => {
        fetch("/api/news/queryNewsByMark", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify({"mark": this.props.mark, "type": lang === 'en' ? 2 : 1})
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    content: data.body.newsContent
                });
            })
    }
    render(props, state, context) {
        const currentLang = context.__cC0.props.value;
        if (currentLang !== this.lang) {
            this.lang = currentLang;
            this.loadData(currentLang);
            return null;
        }

        const content = this.state.content.replace("等线", "");
        return (
            <div className={style.newsContent}>
                {ReactHtmlParser(this.state.content)}
            </div>
        );
    }
}