import {Component, Fragment} from "preact";

import style from './style.css'
import {route} from "preact-router";
import {useContext} from "preact/hooks";
import Lang from "../../../lang";

export default class List extends Component {
    constructor() {
        super();
        this.state = {
            list: [],
            isLastPage: false
        }
    }

    page = 1

    img = {
        "footwear": "http://image.larutadevia.com/laru-mobile/category/banner-2-1024-min.jpg",
        "bags": "http://image.larutadevia.com/laru-mobile/category/banner-1-1024-min.jpg",
        "accessories": "http://image.larutadevia.com/laru-mobile/category/banner-3-1024-min.jpg"
    }

    categoryIds = {
        "footwear": 1,
        "bags": 2,
        "accessories": 6
    }

    componentDidMount() {
        this.nextPage();
    }

    render() {
        return (
            <div>
                <img style={{width: "100%"}} src={this.img[this.props.type]}/>
                <div className={style.list}>
                    {
                        this.state.list.map(item => (
                            <div className={style.item} onClick={() => route(`/item/${item.goodsCode}`)}>
                                <img src={item.image}/>
                                <div>{item.goodsCode}</div>
                                <span>￥{item.price}</span>
                            </div>
                        ))
                    }
                </div>
                {this.renderMoreBtn()}
            </div>
        );
    }

    renderMoreBtn = () => {
        if (!this.state.isLastPage) {
            const lang = useContext(Lang);
            return (
                <div onClick={this.nextPage} className={style.more}>
                    {lang === 'zh' ? '加载更多' : 'LOAD MORE'}
                </div>
            );
        }
    }

    nextPage = () => {
        fetch("/api/goods/queryGoodsList", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                categoryId: this.categoryIds[this.props.type],
                type: 1,
                page: this.page++,
                limit: 10,
                shelves: 1
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState(prev => {
                    const list = prev.list.concat(data.body.list);
                    return {
                        list,
                        isLastPage: data.body.isLastPage
                    }
                });
            })
    }
}