import {Component} from "preact";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import style from './style.css'
import {useContext} from "preact/hooks";
import Lang from "../../../lang";

import plus from '../img/plus.svg';
import sub from '../img/minus.svg';


export default class Item extends Component {
    constructor() {
        super();
        this.state = {
            item: null,
            s1: false,
            s2: false
        }
    }

    lang = useContext(Lang);

    data = {
        zh: {
            text: ["成分", "保养说明"],
            shoes: [
                <div>
                    <h5></h5>
                    <div className={style.line}>
                        <p>1.&nbsp;&nbsp;</p>
                    <p>日常清洁：可视鞋面材质和灰尘程度而使用软毛刷子、全棉织布等清洁</p>
                    </div>
                    <div className={style.line}>
                        <p>2.&nbsp;&nbsp;</p>
                    <p>干燥通风： 鞋子应置于干燥，通风清凉处存放，切勿暴晒</p>
                    </div>
                    <div className={style.line}>
                        <p>3.&nbsp;&nbsp;</p>
                    <p>避免硬物碰撞：皮鞋不应作剧烈运动；应避免尖锐物品接触，以防划伤鞋面鞋身</p>
                    </div>
                    <div className={style.line}>
                        <p>4.&nbsp;&nbsp;</p>
                    <p>勤替换：经常替换鞋，延长鞋子使用寿命</p>
                    </div>
                    <div className={style.line}>
                        <p>5.&nbsp;&nbsp;</p>
                    <p>贴大底保护：真皮大底不建议在雨天或不平整的水泥路面穿着行走，如想更好地保护鞋底，可尝试贴大底</p>
                    </div>
                    <div className={style.line}>
                        <p>6.&nbsp;&nbsp;</p>
                    <p>避免接触化学物：不要接触酒精、油脂（香水、化妆品、护肤品等），以及酸、碱、海水等腐蚀性液体</p>
                    </div>
                </div>,
                <div>
                    <p style={{marginTop: "10%"}}>不同材质的保养方式：</p>
                    <div className={style.line}>
                        <p>7.&nbsp;&nbsp;</p>
                    <p>牛皮/羊皮：使用柔软鞋布把污垢去除，再涂适量专业皮鞋护理膏，用软布在鞋面上来回擦抹，令鞋面光泽</p>
                    </div>
                    <div className={style.line}>
                        <p>8.&nbsp;&nbsp;</p>
                    <p>蛇皮：不能使用鞋油，可用含蜡的海绵或洁净的干绒布打理</p>
                    </div>
                    <div className={style.line}>
                        <p>9.&nbsp;&nbsp;</p>
                    <p>油性皮/打腊皮：用干布或鞋油清洁，避免沾水</p>
                    </div>
                    <div className={style.line}>
                        <p>10.&nbsp;&nbsp;</p>
                    <p>磨砂皮/翻毛皮：用毛刷轻刷，或使用专用清洁剂，若遇顽固污渍，可用少量清水及肥皂轻擦鞋面，等吹干后再刷绒毛表层</p>
                    </div>
                    <div className={style.line}>
                        <p>11.&nbsp;&nbsp;</p>
                    <p>漆皮/PU成分材料：用半湿棉布擦拭，即可恢复原有亮光</p>
                    </div>
                    <div className={style.line}>
                        <p>12.&nbsp;&nbsp;</p>
                    <p>布面/织物：用干布掸去鞋面灰尘，可使用湿布蘸稀释的洗涤剂轻轻擦拭，清洁后应自然晾干</p>
                    </div>
                </div>
            ],
            bags: [
                <div>
                    <h4></h4>
                    <div className={style.line}>
                        <p>1.&nbsp;&nbsp;</p>
                        <p>请勿撞击或反复摩擦，以免其表面受损</p>
                    </div>
                    <div className={style.line}>
                        <p>2.&nbsp;&nbsp;</p>
                        <p>不要接触酒精、油脂（香水、化妆品、护肤品等），以及酸、碱、海水等腐蚀性液体</p>
                    </div>
                    <div className={style.line}>
                        <p>3.&nbsp;&nbsp;</p>
                        <p>金属配饰在日常生活中有磨损属正常；请小心防潮，经常用软布擦拭，以免腐蚀</p>
                    </div>
                    <div className={style.line}>
                        <p>4.&nbsp;&nbsp;</p>
                        <p>箱包收纳保存时应塞填充物，装入防尘袋并保持干燥，以免挤压变形</p>
                    </div>
                    <div className={style.line}>
                        <p>5.&nbsp;&nbsp;</p>
                        <p>不要与其他皮具直接接触存放，以免互相染色</p>
                    </div>
                    <div className={style.line}>
                        <p>6.&nbsp;&nbsp;</p>
                        <p>若不慎遇水，请使用干燥软布仔细擦干</p>
                    </div>
                </div>
            ],
            hats: [
                <div>
                    <h4></h4>
                    <div className={style.line}>
                        <p>1.&nbsp;&nbsp;</p>
                        <p>防尘防变形：妥善保存摆放，不要压重物、切忌锐物撞击，如果长期不佩戴在草帽上覆上干净的布或塑料纸，避免灰尘</p>
                    </div>
                    <div className={style.line}>
                        <p>2.&nbsp;&nbsp;</p>
                        <p>防潮存贮：戴过的草帽放置起来前，草帽朝上在通风处晾晒10分钟，除去因人体产生的潮气</p>
                    </div>
                    <div className={style.line}>
                        <p>3.&nbsp;&nbsp;</p>
                        <p>切忌接触化学剂：不要接触酒精、油脂（香水、化妆品、护肤品等），以及酸、碱、海水等腐蚀性液体</p>
                    </div>
                    <div className={style.line}>
                        <p>4.&nbsp;&nbsp;</p>
                        <p>忌水洗干洗：帽子避免使用水洗或干洗的洗涤方式，可以用清洁的棉布裹在手指上，沾点清水轻轻擦，然后再晾干，不要潮湿的时候套上包装袋，以防草质产生霉点</p>
                    </div>
                </div>
            ]
        },
        en: {
            text: ["MATERIAL", "INSTRUCTIONS"],
            shoes: [
                <div>
                    <h4></h4>
                    <div className={style.line}>
                        <p>1.&nbsp;&nbsp;</p>
                        <p>Daily cleaning: Banister brush, all cotton fabric and the like may be used to clean based on
                            the texture and dust degree of shoe upper.</p>
                    </div>
                    <div className={style.line}>
                        <p>2.&nbsp;&nbsp;</p>
                        <p>Drying and ventilation: Shoes should be stored in drying, ventilated and cool place, and
                            shall
                            not be under the blazing sun.</p>
                    </div>
                    <div className={style.line}>
                        <p>3.&nbsp;&nbsp;</p>
                        <p>Avoid collision by hard objects: Strenuous exercise shall be avoided when putting on leather
                            shoes; contact with sharp objects shall be avoided to prevent them from scratching shoe
                            upper
                            and shoe body.</p>
                    </div>
                    <div className={style.line}>
                        <p>4.&nbsp;&nbsp;</p>
                        <p>Frequent replacement: Frequently replace shoes to extend the useful life of shoes.</p>
                    </div>
                    <div className={style.line}>
                        <p>5.&nbsp;&nbsp;</p>
                        <p>Protect by pasting outsole: It is suggested to not walk on rainy days or on irregular
                            concrete
                            payment when putting on shoes with real-leather outsole. Users may try to paste outsole to
                            better protect shoe sole.</p>
                    </div>
                    <div className={style.line}>
                        <p>6.&nbsp;&nbsp;</p>
                        <p>Avoid contact with chemicals: Do not contact alcohol, grease (perfume, cosmetics, skin care
                            products, etc.), and acid, alkaline, seawater and other corrosive liquid.</p>
                    </div>
                </div>,
                <div>
                    <h4></h4>
                    <div className={style.line}>
                        <p>7.&nbsp;&nbsp;</p>
                        <p>Cow leather/sheepskin leather: Remove dirt with soft fabric, apply proper amount of
                            specialized
                            leather care cream, and wipe shoe upper back and forth with soft fabric to make shoe upper
                            glossy.</p>
                    </div>
                    <div className={style.line}>
                        <p>8.&nbsp;&nbsp;</p>
                        <p>Snake leather: Shoe cream should not be used; wax containing sponge or clean dry lint may be
                            used.</p>
                    </div>
                    <div className={style.line}>
                        <p>9.&nbsp;&nbsp;</p>
                        <p>Oily leather/burnished leather: Clean with dry fabric or shoe cream, and avoid being stained
                            with water.</p>
                    </div>
                    <div className={style.line}>
                        <p>10.&nbsp;&nbsp;</p>
                        <p>Nubuck leather/anti-fur: Slightly brush with fur brush, or use specialized cleanser; small
                            amount of clear water and soap may be used to slightly scrub shoe upper to remove stubborn
                            stain, and brush the surface layer of fluff after drying.</p>
                    </div>
                    <div className={style.line}>
                        <p>11.&nbsp;&nbsp;</p>
                        <p>Patent leather/PU materials: Scrub with semi-humid cotton fabric so that the materials can
                            return to the original brilliance.</p>
                    </div>
                    <div className={style.line}>
                        <p>12.&nbsp;&nbsp;</p>
                        <p>Cloth cover/fabric: Flip off dust on shoe upper with dry cloth, may use wet cloth dipped in
                            diluted detergent to slightly scrub shoe upper; ensure natural dry after cleaning.</p>
                    </div>
                </div>
            ],
            bags: [
                <div>
                    <h4></h4>
                    <div className={style.line}>
                        <p>1.&nbsp;&nbsp;</p>
                        <p>Please don’t strike or rub repeatedly to avoid surface damage.</p>
                    </div>
                    <div className={style.line}>
                        <p>2.&nbsp;&nbsp;</p>
                        <p>Do not contact alcohol, grease (perfume, cosmetics, skin care products, etc.), and acid,
                            alkaline, seawater and other corrosive liquid.</p>
                    </div>
                    <div className={style.line}>
                        <p>3.&nbsp;&nbsp;</p>
                        <p>Abrasion of metal accessories is normal in daily life; please carefully keep away from
                            moisture, frequently scrub with soft cloth, and avoid corrosion.</p>
                    </div>
                    <div className={style.line}>
                        <p>4.&nbsp;&nbsp;</p>
                        <p>Bags and suitcases should be jammed with stuffing, put in dustproof bag and kept dry when
                            being
                            stored, so as to avoid compressional deformation.</p>
                    </div>
                    <div className={style.line}>
                        <p>5.&nbsp;&nbsp;</p>
                        <p>Do not directly contact and be stored with other leather products, so as to avoid mutual
                            dyeing.</p>
                    </div>
                    <div className={style.line}>
                        <p>6.&nbsp;&nbsp;</p>
                        <p>Please carefully wipe dry by using dry, soft cloth when being touched with water
                            carelessly.</p>
                    </div>
                </div>
            ],
            hats: [
                <div>
                    <h4></h4>
                    <div className={style.line}>
                        <p>1.&nbsp;&nbsp;</p>
                        <p>Dustproof and anti-defomation: Properly store and put the product, do not put heavy objects
                            on
                            it, and avoid strike by sharp objects. If you do not wear it for a long time, please cover
                            dry
                            cloth or plastic paper on the straw hat to avoid dust.</p>
                    </div>
                    <div className={style.line}>
                        <p>2.&nbsp;&nbsp;</p>
                        <p>Dampproof storage: Please dry the straw hat worn in the sun for 10 minutes before storing it,
                            so as to avoid humidity generated from human body.</p>
                    </div>
                    <div className={style.line}>
                        <p>3.&nbsp;&nbsp;</p>
                        <p>Avoid contact with chemicals: Do not contact alcohol, grease (perfume, cosmetics, skin care
                            products, etc.), and acid, alkaline, seawater and other corrosive liquid.</p>
                    </div>
                    <div className={style.line}>
                        <p>4.&nbsp;&nbsp;</p>
                        <p>Avoid washing and dry cleaning: Avoid the washing and dry cleaning of hat; users may wrap
                            clean
                            cotton cloth on finger, dip clear water to slightly scrub, and dry in the air; do not put
                            the
                            moist hat in packing bag to avoid mildew on grass texture.</p>
                    </div>
                </div>
            ]
        },
    }

    componentDidMount() {
        this.loadData(this.lang);
    }

    loadData = (lang) => {
        fetch("/api/goods/queryGoodsListByCode", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify({"type": lang === 'en' ? 2 : 1, "goodsCode": this.props.code})
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    item: data.body
                });
            })
    }

    render(props, state, context) {
        const currentLang = context.__cC0.props.value;
        if (currentLang !== this.lang) {
            this.lang = currentLang;
            this.loadData(currentLang);
            return null;
        }

        const {item, s1, s2} = this.state;
        if (!item) {
            return;
        }

        let category = "bags";

        if (item.categoryId === 1) {
            category = "shoes";
        } else if (item.categoryId === 6) {
            category = "hats";
        }

        const lang = useContext(Lang);
        const langData = this.data[lang];

        return (
            <div className={style.item}>
                <div style={{height: window.innerWidth, paddingLeft: 0}}>
                    <img src={item.longImage}/>
                </div>
                <div>{item.goodsCode}</div>
                <div>￥{item.price}</div>
                <div>{item.color}</div>

                <div onClick={this.toggleS1}
                     className={[style.tableDiv, style.borderTop, s1 ? null : style.borderBottom].join(" ")}>
                    {langData.text[0]}
                    <img src={s1 ? sub : plus}
                         style={{float: "right", width: "10px"}}></img>
                </div>
                <div style={{display: s1 ? "block" : "none", fontSize: "0.8em"}}
                     className={[style.tableDiv, style.borderBottom].join(" ")}>{item.composition}</div>

                <div onClick={this.toggleS2} className={[style.tableDiv, s2 ? null : style.borderBottom].join(" ")}>
                    {langData.text[1]}
                    <img src={s2 ? sub : plus}
                         style={{float: "right", width: "10px"}}>-</img></div>
                <div style={{display: s2 ? "block" : "none"}}
                     className={[style.tableDiv, style.borderBottom].join(" ")}>
                    {langData[category]}
                </div>
            </div>
        );
    }

    toggleS1 = () => {
        this.setState(prev => {
            return {
                s1: !prev.s1
            }
        })
    }


    toggleS2 = () => {
        this.setState(prev => {
            return {
                s2: !prev.s2
            }
        })
    }
}