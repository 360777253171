
		import Async from "../../node_modules/@preact/async-loader/async-legacy.js";

		function load(cb) {
			require.ensure([], function (require) {
				cb( require("!!../../node_modules/babel-loader/lib/index.js??ref--4!./index.js") );
			}, "route-home");
		}

		export default Async(load);
	